<template>
  <div>
    <b-card>
      <div class="d-lg-flex justify-content-between align-items-center">
        <h3 class="font-weight-bolder">
          {{ $t('deliveryType') }}
        </h3>
        <div class="income-order-search order-search">
          <div class="anan-input search-input">
            <div class="anan-input__inner anan-input__inner--normal">
              <input
                v-model="filter"
                type="text"
                :placeholder="$t('search')"
                class="anan-input__input"
              >
              <div class="anan-input__suffix">
                <i class="anan-input__clear-btn anan-icon" />
                <i class="anan-input__suffix-icon anan-icon">
                  <i class="fal fa-search" />
                </i>
              </div>
            </div>
          </div>
        </div>
      </div>

      <router-link :to="{ name: 'admin-shipping-type-create' }">
        <button class="anan-button--xl-large anan-button anan-button--primary anan-button--normal mt-1">
          <span> {{ $t('key-70') }} </span>
        </button>
      </router-link>
      <b-overlay
        :show="showOver"
        variant="transparent"
        opacity="0.99"
        blur="5px"
        rounded="sm"
      >
        <template #overlay>
          <div class="text-center">
            <p id="cancel-label">
              กรุณารอสักครู่...
            </p>
          </div>
        </template>
        <b-table
          striped
          hover
          responsive
          :total-rows="totalRows"
          :per-page="perPage"
          :items="items"
          :fields="fields"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :sort-direction="sortDirection"
          :filter="filter"
          :filter-included-fields="filterOn"
          show-empty
        >
          <template #empty>
            <div class="text-center">
              <img
                src="@/assets/images/anan-img/empty/empty.png"
                alt="empty"
                width="180px"
              >
              <p>No Data</p>
            </div>
          </template>

          <template #cell(number)="data">
            <b-link :to="{name: 'admin-role-edit', params: {id: data.item._id}}">
              SHIP-{{ items.length - (perPage * (currentPage - 1) + (data.index + 1)) + 1 }}
            </b-link>
          </template>

          <template #cell(sequence)="data">
            <b-form-input
              v-model="data.item.sequence"
              type="number"
              class="text-center"
              @input="CheckUpdate(data.item.sequence, 'sequence',data.item._id)"
            />
          </template>

          <template #cell(delivery_source)="data">
            <b-form-checkbox
              v-model="data.item.delivery_source"
              name="payShippingOrigin"
              switch
              inline
              :value="1"
              :unchecked-value="0"
              @change="CheckUpdate(data.item.delivery_source, 'delivery_source',data.item._id)"
            />
          </template>

          <template #cell(destination)="data">
            <b-form-checkbox
              v-model="data.item.destination"
              name="payDeliveryFee"
              switch
              inline
              :value="1"
              :unchecked-value="0"
              @change="CheckUpdate(data.item.destination, 'destination',data.item._id)"
            />
          </template>

          <template #cell(actions)="data">
            <feather-icon
              icon="Edit3Icon"
              class="mr-1 text-primary cursor-pointer"
              @click="EditData(data.item)"
            />

            <feather-icon
              class="text-danger cursor-pointer"
              icon="TrashIcon"
              @click="DeleteData(data.item)"
            />
          </template>
        </b-table>
      </b-overlay>
      <div class="demo-spacing-0 d-flex justify-content-end">
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          first-number
          last-number
          prev-class="prev-item"
          next-class="next-item"
          class="mb-0"
          @input="getData()"
        >
          <template #prev-text>
            <feather-icon
              icon="ChevronLeftIcon"
              size="18"
            />
          </template>
          <template #next-text>
            <feather-icon
              icon="ChevronRightIcon"
              size="18"
            />
          </template>
        </b-pagination>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BTable, BCard, BLink, BFormInput, BFormCheckbox, BPagination, BOverlay,
} from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BTable,
    BLink,
    BFormInput,
    BFormCheckbox,
    BPagination,
    BOverlay,
  },
  data() {
    return {
      perPage: 10,
      currentPage: 1,
      totalRows: 0,
      filter: null,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filterOn: [],
      search: '',
      isActive: 'all',
      items: [],
      productlist: [
        { text: 'รถ', value: 1 },
        { text: 'เรือ', value: 2 },
      ],
      productDate: {
        id: null,
        name: null,
        detail: null,
        type: null,
        kilo: 0,
        queue: 0,
      },
      Obj: {
        name: null,
        service: 0,
        print: 0,
        delivery_source: 0,
        destination: 0,
        footnote: null,
        delivery_type: 1,
        rate: [],
        agent_id: null,
      },
      showOver: false,
    }
  },
  computed: {
    fields() {
      return [
        {
          key: 'number', label: this.$t('number'), thStyle: { width: '10%' }, thClass: 'text-center',
        },
        {
          key: 'sequence', label: this.$t('sequence'), sortable: false, thStyle: { width: '10%' }, thClass: 'text-center',
        },
        {
          key: 'name', label: this.$t('firstName'), sortable: false, thStyle: { width: '10%' }, thClass: 'text-center',
        },
        {
          key: 'delivery_source', label: this.$t('shippingFeeOrigin'), sortable: false, thStyle: { width: '10%' }, thClass: 'text-center',
        },
        {
          key: 'destination', label: this.$t('payDestinationShipping'), sortable: false, thStyle: { width: '15%' }, thClass: 'text-center', tdClass: 'text-center',
        },
        {
          key: 'footnote', label: this.$t('details'), sortable: false, thStyle: { width: '40%' }, thClass: 'text-center', tdClass: 'text-right',
        },
        {
          key: 'delivery_type',
          label: this.$t('type'),
          sortable: false,
          thStyle: { width: '10%' },
          thClass: 'text-center',
          tdClass: 'text-right',
          formatter: value => {
            if (value === 1) {
              return this.$t('byWeightKg')
            } if (value === 2) {
              return this.$t('byVolumeCubicMeter')
            }
            return '-'
          },
        },
        {
          key: 'actions', label: this.$t('actions'), sortable: false, thStyle: { width: '5%' }, thClass: 'text-center', tdClass: 'text-center',
        },
      ]
    },
  },
  mounted() {
    this.getData('all')
  },
  methods: {
    async getData(type) {
      this.showOver = true
      this.isActive = type
      try {
        const params = {
          page: this.currentPage,
          perPage: this.perPage,
        }
        const { data: res } = await this.$http.get('/shipping_type/shipping_showall', { params })
        this.items = res.data
        this.totalRows = res.total
        this.showOver = false
      } catch (e) {
        console.log(e)
        this.showOver = false
      }
    },
    CheckUpdate(data, fields, ID) {
      console.log(data, fields, ID)
      if (data || data === 0) {
        const obj = {
          data,
          fields,
          ID,
        }
        this.$http.post('/shipping_type/shipping_update_filed', obj)
      }
    },
    EditData(data) {
      this.$router.push({
        // eslint-disable-next-line no-underscore-dangle
        name: 'admin-shipping-type-edit', params: { id: data._id },
      })
      console.log(data)
    },
    DeleteData(data) {
      this.$bvModal.msgBoxConfirm('คุณต้องการลบข้อมูลใช่หรือไม่?', {
        title: 'ยืนยันการลบข้อมูล',
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'danger',
        okTitle: 'YES',
        cancelTitle: 'NO',
        footerClass: 'p-2',
        hideHeaderClose: false,
        centered: true,
      })
        .then(value => {
          if (value) {
            const params = {
              // eslint-disable-next-line no-underscore-dangle
              ID: data._id,
            }
            this.$http.post('shipping_type/shipping_delete', params).then(() => {
              this.getData(this.isActive)
              this.$bvToast.toast('ลบข้อมูลสำเร็จ', {
                title: 'สำเร็จ',
                variant: 'success',
                solid: true,
                autoHideDelay: 1500,
              })
            })
          }
        })
        .catch(err => {
          console.log(err)
        })
    },
    submitNew() {
      const obj = {
        id: this.productDate.id,
        name: this.productDate.name,
        detail: this.productDate.detail,
        type: this.productDate.type,
        kilo: this.productDate.kilo,
        queue: this.productDate.queue,
      }
      this.$http
        .post('/product_type/product_update', obj)
        .then(() => {
          this.$bvToast.toast('บันทึกข้อมูลสำเร็จ', {
            title: 'Success',
            variant: 'success',
            solid: true,
          })
        })
        .catch(error => {
          console.log(error)
          // this.SwalError(error.response.data.message)
        })
    },

  },
}
</script>

      <style lang="scss" scoped></style>
