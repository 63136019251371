import { render, staticRenderFns } from "./shipping-type.vue?vue&type=template&id=a3202eee&scoped=true&"
import script from "./shipping-type.vue?vue&type=script&lang=js&"
export * from "./shipping-type.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a3202eee",
  null
  
)

export default component.exports